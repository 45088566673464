<template>
  <div class="main">
    <breadcrumb>
      <breadcrumb-item>{{$t('OrderManagement')}}</breadcrumb-item>
      <breadcrumb-item>{{$t('AllOrders')}}</breadcrumb-item>
    </breadcrumb>
    <div class="filter cl">
      <div class="item cl">
        <div class="label">{{$t('Order number')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.orderNo"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Order Contact')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.contactName"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Group status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable v-model="status" size="small" :placeholder="$t('All')">
            <el-option v-for="item in statusOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductNumber')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.productNo"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Contact Number')}}：&#x3000;</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.contactPhone"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Order status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable size="small" :placeholder="$t('All')" v-model="filterData.orderStatus">
            <el-option v-for="(item,key) of $t('OrderStatus')"
                       :key="key"
                       :label="item"
                       :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductName')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.productTitle"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Settlement Status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable size="small" :placeholder="$t('All')" v-model="filterData.settlementStatus">
            <el-option v-for="(item,key) of $t('SettlementStatus')"
                       :key="key"
                       :label="item"
                       :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Reconciliation Status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable size="small" :placeholder="$t('All')" v-model="filterData.reconciliationStatus">
            <el-option v-for="(item,key) of $t('ReconciliationStatus')"
                       :key="key"
                       :label="item"
                       :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="fl">
        <button class="button button-primary small m-r" type="button" @click="getOrderList(1)">{{$t('search')}}</button>
        <button class="button small" type="button" @click="clearFilterData">{{$t('Clear')}}</button>
      </div>
    </div>
    <tabs v-model="activeName">
      <tab-pane :label="$t('All')" name="all"></tab-pane>
      <tab-pane :label="$t('Pending')" name="NOT_SIGN_UP_SUCCESS"></tab-pane>
      <tab-pane :label="$t('Pending a group notice')" name="NOT_GROUP"></tab-pane>
<!--      <tab-pane :label="$t('Cancel review')" name="status3"></tab-pane>-->
      <tab-pane :label="$t('Customer Cancellation')" name="USER_CHECK_REFUND"></tab-pane>
    </tabs>
    <div class="table border" v-loading="loadingTable">
      <div style="overflow-x:auto">
        <div style="width:1800px">
          <table>
            <thead>
            <tr>
              <th></th><!--未發出退款單警告-->
              <th>{{$t('Operating')}}</th><!--操作-->
              <th>{{$t('TradingStatus')}}</th> <!--交易狀態-->
              <th>{{$t('Order status')}}</th> <!--訂單狀態-->
              <th>{{$t('Order number')}}</th> <!--訂單編號-->
              <th>{{$t('CreationTime')}}</th> <!--创建时间-->
              <th>{{$t('GroupNumber')}}</th> <!--团号-->
              <th>{{$t('ProductNumber')}}</th> <!--產品編號-->
              <th width="250">{{$t('ProductName')}}</th> <!--產品名稱-->
              <th>{{$t('Route')}}</th> <!--路線-->
              <th>{{$t('Date of departure')}}</th> <!--出團日期-->
              <th>{{$t('Group status')}}</th> <!--成團狀態-->
              <th>{{$t('Order Source')}}</th> <!--訂單來源-->
              <th>{{$t('Number of people')}}</th> <!--人數-->
              <th>{{$t('NumberOfRooms')}}</th> <!--房間數目-->
              <th>{{$t('Amount')}}</th> <!--金额-->
              <th>{{$t('Payment method')}}</th> <!--支付方式-->
              <th>{{$t('CancellationTime')}}</th> <!--取消時間-->
              <th>{{$t('CancellationType')}}</th> <!--取消類型-->
              <th>{{$t('RefundStatus')}}</th> <!--退款狀態-->
              <th>{{$t('RefundAmount')}}</th> <!--退款金額-->
              <th>{{$t('Billing status')}}</th> <!--結算狀態-->
              <th>{{$t('Reconciliation Status')}}</th> <!--對帳狀態-->
              <!--<th>{{$t('Notification Status')}}</th>--><!--通知狀態-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="item of list" :key="item.orderId">
              <td class="nowrap"><!--未發出退款單的警告-->
              <el-popover
                v-if="showOrderCancelStatus(item) === 'red' "
                placement="top-start"
                title="有正在草擬的退款單"
                trigger="hover">
                <img slot="reference"  src="../../../assets/images/icon/alert_r.png"/>
                <slot>
                  <span>您有正在編輯但未發出的退款單</span>
                  <router-link
                    class="btn-item" target="_blank"
                    :to="{name:'AdminCancelOrderApplication',query:{id:item.orderId}}" >
                    按此跳轉
                  </router-link>
                </slot>
              </el-popover>
              <!--用戶未確認退款單的提示-->
              <el-popover
                v-if="showOrderCancelStatus(item) === 'yellow' "
                placement="top-start"
                title="待客戶確認"
                trigger="hover">
                <img slot="reference"  src="../../../assets/images/icon/alert_y.png"/>
                <slot>
                  <span>您的退款單正待客確認</span>
                </slot>
              </el-popover>
              <router-link
                v-if="!showOrderCancelStatus(item)"
                class="btn-item" target="_blank"
                :to="{name:'AdminOrderDetail',query:{id:item.orderId}}" >
                <div v-bind:style="{ height: '16px', width: '16px'}" />
              </router-link>
              </td>

              <td class="nowrap">
              <router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                <img src="../../../assets/images/icon/review.png"/>
              </router-link>
              </td>
              <td><span :class="{fc1:item.transationStatus===3||item.transationStatus===2}">{{$t('TransationStatus['+item.transationStatus+']')}}</span></td> <!--交易狀態-->
              <td>{{$t('OrderStatus')[item.orderStatus]}}</td> <!--訂單狀態-->
              <td>
                <router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
                  {{item.orderNo}}
                </router-link>
                </td> <!--訂單編號-->
              <td>{{item.addDate|formatDate('yyyy.MM.dd hh:mm')}}</td> <!--创建时间-->
              <td>
                <router-link class="" target="" :to="{name:'AdminOrderGroupNumberDetail',query:{groupNumber:item.groupNumber}}">
                  {{item.groupNumber}}
                </router-link>
              </td> <!--团号-->
              <td class="nowrap">{{item.productNo}}</td> <!--產品編號-->
              <td>{{item.productTitle}}</td> <!--產品名稱-->
<!--              <td class="nowrap">{{$t('RoutesDays',[item.lineName,item.days||item.lates])}}</td> &lt;!&ndash;路線&ndash;&gt;-->
              <td class="nowrap">{{ item.lineName+"-"+item.lineTitle }}</td> <!--路線-->
              <td class="nowrap">{{item.priceDate}}</td> <!--出團日期-->
              <td>
                <!--<i class="status-circle"></i><span class="vam">已成团</span>-->
                {{item.groupStatus}}
              </td> <!--成團狀態-->
              <td>{{item.orderSource|dictName('orderSource')}}</td> <!--訂單來源-->
              <td>{{item.orderPeopleNumber}}</td> <!--人數-->
              <td>{{item.roomsNumber}}</td> <!--房間數目-->
              <td class="nowrap">{{item.billingType}} {{item.totalMoney||item.totalPrice}}</td> <!--金额-->
              <td class="nowrap">{{item.payPlatform|dictName('payPlatform')}}</td>  <!--支付方式-->
              <td></td> <!--取消時間-->
              <td></td> <!--取消類型-->
              <td></td> <!--退款狀態-->
              <td></td> <!--退款金額-->
              <td>{{$t("SettlementStatus")[item.settlementStatus]}}</td> <!--結算狀態-->
              <td>{{$t("ReconciliationStatus")[item.reconciliationStatus]}}</td> <!--對帳狀態-->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="getOrderList"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import Breadcrumb from '../../../components/admin/Breadcrumb';
  import BreadcrumbItem from '../../../components/BreadcrumbItem';
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Empty from '../../../components/Empty';
  import {scrollToY} from '../../../utils';
  import {getOrderList} from '../../../api/admin-order';

  export default {
    name: 'AdminOrderList',
    data() {
      return {
        loadingTable: false,
        activeName: 'all',
        statusOptions: [
          {value: '1', label: '名額充足'},
          {value: '2', label: '已成團'},
          {value: '3', label: '已滿額'}
        ],
        status: '',
        filterData: {
          isMy: 'N',
          orderNo: '',
          contactName: '',
          productNo: '',
          contactPhone: '',
          orderStatus: '',
          productTitle: '',
          settlementStatus: '',
          reconciliationStatus: ''
        },
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty, Breadcrumb, BreadcrumbItem, Tabs, TabPane},
    created() {
      this.filterData.isMy = this.$route.query.type === 'my' ? 'Y' : 'N';
      this.getOrderList();
    },
    methods: {
      openOrderListDetail(id) {
        const routeUrl = this.$router.resolve({name: 'AdminOrderListDetail', params: {id}});
        window.open(routeUrl.href, '_blank');
      },
      // 获取订单列表
      getOrderList(page = 1) {
        this.loadingTable = true;
        const params = {page, perPage: 20};
        getOrderList(params, this.filterData).then(res => {
          const {value} = res;

          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = params.page === 1 && !this.list.length;
          this.$nextTick(() => {
            scrollToY();
          });
          this.loadingTable = false;
        });
      },
      // 清除过滤参数
      clearFilterData() {
        let filterData = this.$options.data().filterData;
        filterData.isMy = this.$route.query.type === 'my' ? 'Y' : 'N';
        this.filterData = filterData;
        this.getOrderList();
      },
      // 計算退款單狀態
      // 返回 "yellow", "red" 或者 null
      showOrderCancelStatus(item) {
        // 若 transaction_status 為 close(1), order_status 為 BUSINESS_CHECK_REFUND,
        // 以及有退款單(orderCancelId!=null), 顯示黃色icon (退款單正待客確認)
        if (item.transationStatus === 1 && item.orderStatus === 'BUSINESS_CHECK_REFUND' && item.orderCancelId) {
          return 'yellow';
        }
        // 若 transaction_status 為 normal(0), order_status 為 NOT_SIGN_UP_SUCCESS, NOT_GROUP, YES_GROUP
        // 以及有退款單(orderCancelId!=null), 顯示紅色icon (有正在草擬的退款單)
       if (item.transationStatus === 0 &&
          (item.orderStatus === 'NOT_SIGN_UP_SUCCESS' || item.orderStatus === 'NOT_GROUP' || item.orderStatus === 'YES_GROUP') &&
          item.orderCancelId) {
          return 'red';
        }
        return null;
      }
    },
    watch: {
      $route: function (value) {
        this.clearFilterData();
      },
      activeName: function (value) {
        console.log(value);
        if (value === 'all') {
          this.filterData.orderStatus = '';
        } else {
          this.filterData.orderStatus = value;
        }
        this.getOrderList();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    /deep/ .tab-nav{
      margin-top:6px;
      .item{
        width:auto;margin-right:32px;
        &:after{right:0;left:0;width:auto;}
        a{min-width:auto;}
      }
    }
    .status-circle{
      display:inline-block;width:6px;height:6px;margin-right:10px;vertical-align:middle;background-color:#00b680;border-radius:50%;
      &.orange{background-color:#ff9b00;}
      &.gray{background-color:#999;}
    }
  }
  .filter{
    margin-top:30px;
    .item{
      float:left;width:33.3333%;margin-bottom:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:160px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .select{width:160px;vertical-align:top;}
  }
  .fc1{color:#ff6f61;}
</style>
